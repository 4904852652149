import React, { Component } from 'react'
import '../style/Exception.css'

class Exception extends Component {

  render() {

    let strSubheader = {__html: ''}
    let strDescription = {__html: ''}

      if (this.props.dataFrame.subheader['value']) strSubheader = {__html: this.props.dataFrame.subheader['value']}
      if (this.props.dataFrame.description['value']) strDescription = {__html: this.props.dataFrame.description['value']}

    return (
      <div className="exception">
        <h2
          dangerouslySetInnerHTML={strSubheader}
        />
        <p
          className="description"
          dangerouslySetInnerHTML={strDescription}
        />
      </div>
    )

  }

}

export default Exception
