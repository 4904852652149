import React, { Component } from 'react'
import '../style/Nav.css'

class Nav extends Component {

  render() {

    return (
      <div className="nav">
        <div
          className="container-nav-buttons">
          <div
            className="nav-button"
            onClick={(e) => window.location = window.location.pathname}
            >
            <span>Reload<br />Display</span>
          </div>
          <div
            className="nav-button logo"
            onClick={(e) => this.props.selectDisplay(e, 'logo')}
            >
          </div>
        </div>
      </div>
    )

  }

}

export default Nav
