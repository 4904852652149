import React, { Component } from 'react'
import '../style/Promos.css'

class Promos extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {

    let strHeader = {__html: ''}
    let strSubheader = {__html: ''}
    let strDescription = {__html: ''}
    let listRows = []

    if (this.props.dataFrame) {

      if (this.props.dataFrame.header['value']) strHeader = {__html: this.props.dataFrame.header['value']}
      if (this.props.dataFrame.subheader['value']) strSubheader = {__html: this.props.dataFrame.subheader['value']}
      if (this.props.dataFrame.description['value']) strDescription = {__html: this.props.dataFrame.description['value']}

      this.props.dataPromos.forEach((item, i) => {
      
        let strPromoHeader = {__html: ''}
        let strPromoDescription = {__html: ''}
        let strImageUrl = ''

        if (item.header['value']) strPromoHeader = {__html: item.header['value']}
        if (item.description['value']) strPromoDescription = {__html: item.description['value']}
        if (item.image) strImageUrl = item.image

        listRows.push(
          <li
            key={i}
            >
              <div className="promo-image">
                <img
                  src={strImageUrl}
                  alt="promo"
                />
              </div>
              <h1
                dangerouslySetInnerHTML={strPromoHeader}
              />
              <div className="promo-descritpion"
                dangerouslySetInnerHTML={strPromoDescription}
              />
          </li>
        )

      })

    }

    return (
      <div className="promos">
        <h1
          dangerouslySetInnerHTML={strHeader}
        />
        <h2
          dangerouslySetInnerHTML={strSubheader}
        />
        <h3
          dangerouslySetInnerHTML={strDescription}
        />
        <div className="promos-list">
          <ul>
            {listRows}
          </ul>
        </div>
      </div>
    )

  }

}

export default Promos
