import React, {Component} from 'react'
import '../style/Logo.css'

class Logo extends Component {

  render() {

    return(
      <div
        id="logo"
        onClick={(e) => this.props.selectDisplay(e, 'nav')}
      />
    )

  }

}

export default Logo
