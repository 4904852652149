import React, { Component } from 'react'
import '../style/App.css'
import Loader from './Loader'
import Nav from './Nav'
import Logo from './Logo'
import Stage from './Stage'
import Frame from './Frame'
import fetchJsonp from 'fetch-jsonp'

class App extends Component {
  constructor() {
    super()

    this.state = {
      display: 'loader', // loader || nav || logo || stage || frame (for preview)
      dataHoursLoaded: false,
      dataApiUse: true, // true || recompile app false for extended static price fallback
      dataApiLoaded: false,
      dataApiPricesDaytimeTimed: false, // true || false for original non-timed daytime pricing
      dataApiPricesNightLifeTimed: false, // true || false for original non-timed NL pricing
      dataApiDaytimePriceAdult: null,
      dataApiDaytimePriceSenior: null,
      dataApiDaytimePriceStudent: null,
      dataApiDaytimePriceYouth: null,
      dataApiNightLifePricePublic: null,
      dataApiNightLifePriceMember: null,
      dataApiNightLifeVipPricePublic: null,
      dataApiNightLifeVipPriceMember: null,
      dataCmsLoaded: false,
      dataCmsScenarios: null,
      dataCmsFrames: null,
      dataCmsItems: null,
      dataCmsPromos: null,
      isLoadedScenarios: false,
      isLoadedFrames: false,
      isLoadedItems: false,
      isLoadedPromos: false,
      activeScenario: null,
      activeFrames: null,
      activeItems: null,
      activePromos: null,
      activeStartTime: null,
      todayOpenTimeUnix: null,
      todayCloseTimeUnix:null,
      isNightLifeToday: false,
      todayNightLifeStart: null,
      todayNightLifeEnd: null
    }

    this.handlerLoadComplete = this._loadComplete.bind(this)
    this.handlerSelectDisplay = this._selectDisplay.bind(this)

    this.intervalId = null
    this.intervalIdDataRefresh = null
    this.intervalIdTimedDaytimePriceRefresh = null
    this.intervalIdTimedNightLifePriceRefresh = null

    this.previewDataFrame = null
    this.previewDataItems = null
    this.previewDataPromos = null

    this.URL_MUSEUM_HOURS = "https://www.calacademy.org/get-museum-hours/"
    // used if isStaging true
    this.URL_MUSEUM_HOURS_STAGING = "https://www-stg.calacademy.org/get-museum-hours/"

    this.URL_CMS_SCENARIOS = "https://www.calacademy.org/rest/tod-scenarios.jsonp"
    this.URL_CMS_FRAMES = "https://www.calacademy.org/rest/tod-frames.jsonp"
    this.URL_CMS_ITEMS = "https://www.calacademy.org/rest/tod-frame-list-items.jsonp"
    this.URL_CMS_PROMOS = "https://www.calacademy.org/rest/tod-promos.jsonp"
    // used if isStaging true
    this.URL_CMS_SCENARIOS_STAGING = "https://www-stg.calacademy.org/rest/tod-scenarios.jsonp"
    this.URL_CMS_FRAMES_STAGING = "https://www-stg.calacademy.org/rest/tod-frames.jsonp"
    this.URL_CMS_ITEMS_STAGING = "https://www-stg.calacademy.org/rest/tod-frame-list-items.jsonp"
    this.URL_CMS_PROMOS_STAGING = "https://www-stg.calacademy.org/rest/tod-promos.jsonp"

    this.API_ENDPOINT_BASE = "https://3n3d3k50kj.execute-api.us-west-2.amazonaws.com/default/todTessituraApiProxy"
    this.API_MODE_OF_SALE = 2 // "Front Gate"
    this.API_PERFORMANCE_TYPE_IDS = {
    	'DA': 1,
    	'NL': 2,
      'NLVIP': 24,
      'TIMED_DA': 42, // Timed Daytime Admission
      'TIMED_NL': 48 // Timed NightLife
    }
    this.API_PRICE_TYPE_IDS = {
    	'ADULT': 1,
    	'SENIOR': 18,
      'STUDENT': 19,
      'YOUTH': 20,
      'MEMBER': 240
    }

    this.dataApiDaytimePerformanceId = null
    this.dataApiNightLifePerformanceId = null
    this.dataApiNightLifeVipPerformanceId = null

    this.headers = {
      'Content-Type': 'application/json'
    }

    this.now = new Date()

    this.queryTimewarp = false
    this.queryFramePreview = false
    this.isStaging = false
    this._queryParams()

  }

  componentDidMount() {
    this._getCmsData("Scenarios")
    this._getCmsData("Frames")
    this._getCmsData("Items")
    this._getCmsData("Promos")
    this._getTodayMuseumHours()
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalIdDataRefresh)
  }

  // 30-min ticketing api data refresh in running app instances
  // will not run if query timewarp or frame preview enabled
  _startApiDataRefreshInterval() {
    if ((!this.queryTimewarp) && (!this.queryFramePreview)) {
      clearInterval(this.intervalIdDataRefresh)
      this.intervalIdDataRefresh = null
      this.intervalIdDataRefresh = setInterval(() => this._getApiData(), 60000 * 30)
    }
  }

  // timed daytime ticket price UI update every 30 sec - no new API data call
  _startTimedDaytimePriceRefreshInterval(zones, prices) {
    if ((!this.queryTimewarp) && (!this.queryFramePreview)) {
      clearInterval(this.intervalIdTimedDaytimePriceRefresh)
      this.intervalIdTimedDaytimePriceRefresh = null
      this.intervalIdTimedDaytimePriceRefresh = setInterval(() => this._setApiDataTimedDaytimePrices(zones, prices), 1000 * 30)
    }
  }

  // timed nightlife ticket price UI update every 30 sec - no new API data call
  _startTimedNightLifePriceRefreshInterval(zones, prices) {
    if ((!this.queryTimewarp) && (!this.queryFramePreview)) {
      clearInterval(this.intervalIdTimedNightLifePriceRefresh)
      this.intervalIdTimedNightLifePriceRefresh = null
      this.intervalIdTimedNightLifePriceRefresh = setInterval(() => this._setApiDataTimedNightLifePrices(zones, prices), 1000 * 30)
    }
  }

  _checkForScenarioChange() {
    this.now = new Date()
    this._parseData()
  }

  _getYearMonthDay(date) {
    var d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
  }

  _compareDates(d1, d2) {
    if (d1 < d2) return 'before'
    if (d1 > d2) return 'after'
    return
  }

  _compareTimes(d1, d2) {
    let hours1 = d1.getHours()
    let hours2 = d2.getHours()
    let minutes1 = d1.getMinutes()
    let minutes2 = d2.getMinutes()
    let time1 = new Date()
    let time2 = new Date()
    time1.setHours(hours1,minutes1,0,0)
    time2.setHours(hours2,minutes2,0,0)
    // hour + minute check
    if (time1 <= time2) {
      return 'before'
    }
    if (time1 >= time2) {
      return 'after'
    }
    return
  }

  _getTodayMuseumHours() {

    const _this = this
    let url = this.URL_MUSEUM_HOURS

    // if staging
    if (this.isStaging) {
      url = this.URL_MUSEUM_HOURS_STAGING
    }

    // if timewarp
    if (this.queryTimewarp) {
      let query = this.queryTimewarp.split("T")
      let vals = query[0].split("-")
      let month = vals[1]
      let date = vals[2]
      let year = vals[0]
      url = url + '?date=' + month + '/' + date + '/' + year
    }

    //fetchJsonp(url)
    fetch(url)
    .then((response) => {
      return response.json()
    }).then((data) => {
      if (data.start.timestamp && data.end.timestamp) {
        // REMOVE 15 minutes from open timestamp
        // REMOVE 60 minutes from close timestamp
        let open = data.start.timestamp - (15 * 60)
        let close = data.end.timestamp - (60 * 60)
        this.setState({
          dataHoursLoaded: true,
          todayOpenTimeUnix: open,
          todayCloseTimeUnix: close
        })
      }
    }).catch((ex) => {
      console.log('Museum Hours fetch failed: fetching again in 30 seconds', ex)
      setTimeout(() => {
        _this._getTodayMuseumHours()
     }, 30000)
    })
  }

  _isNonStandardDaytimeHours() {

    let isNonStandardDaytimeHours = false

    let start = new Date(this.state.todayOpenTimeUnix * 1000)
    let end = new Date(this.state.todayCloseTimeUnix * 1000)

    let day = start.getDay()

    let openHours = start.getHours()
    let openMinutes = start.getMinutes()
    let closeHours = end.getHours()
    let closeMinutes = end.getMinutes()

    // REMINDER: action driving times below reflect
    // 15 & 60 min deductions in _getTodayMuseumHours()

    // if Sunday
    if (day === 0) {
      if (
        (openHours !== 10) ||
        (openMinutes !== 45) ||
        (closeHours !== 16) ||
        (closeMinutes !== 0)
      ) {
        isNonStandardDaytimeHours = true
      }
    // Mon - Sat
    } else {
      if (
        (openHours !== 9) ||
        (openMinutes !== 15) ||
        (closeHours !== 16) ||
        (closeMinutes !== 0)
      ) {
        isNonStandardDaytimeHours = true
      }
    }

    return isNonStandardDaytimeHours

  }

  _getApiData() {

    let nowStartDate = new Date(this.now.getTime())
    let nowEndDate = new Date(this.now.getTime())
    nowStartDate = this._getYearMonthDay(nowStartDate)
    nowEndDate.setDate(nowEndDate.getDate() + 1)
    nowEndDate = this._getYearMonthDay(nowEndDate)

    let url_api_get_pid = this.API_ENDPOINT_BASE + '/Search'
    if (this.isStaging) {
      url_api_get_pid = this.API_ENDPOINT_BASE + '/SearchStaging'
    }

    let idRefDA = 'DA';
    let idRefNL = 'NL';
    if (this.state.dataApiPricesDaytimeTimed) {
      idRefDA = 'TIMED_DA';
    }
    if (this.state.dataApiPricesNightLifeTimed) {
      idRefNL = 'TIMED_NL';
    }

    let request_pid_da = {
      "PerformanceStartDate": nowStartDate,
      "PerformanceEndDate": nowEndDate,
      "ModeOfSaleId": this.API_MODE_OF_SALE,
      "PerformanceTypeIds": this.API_PERFORMANCE_TYPE_IDS[idRefDA]
    }
    let request_pid_nl = {
      "PerformanceStartDate": nowStartDate,
      "PerformanceEndDate": nowEndDate,
      "ModeOfSaleId": this.API_MODE_OF_SALE,
      "PerformanceTypeIds": this.API_PERFORMANCE_TYPE_IDS[idRefNL]
    }
    let request_pid_nlvip = {
      "PerformanceStartDate": nowStartDate,
      "PerformanceEndDate": nowEndDate,
      "ModeOfSaleId": this.API_MODE_OF_SALE,
      "PerformanceTypeIds": this.API_PERFORMANCE_TYPE_IDS['NLVIP']
    }

    this._getApiDataDaytimePerformanceId(url_api_get_pid, request_pid_da)

    // only try NL data if NL today
    if (this.state.isNightLifeToday) {
      this._getApiDataNightLifePerformanceId(url_api_get_pid, request_pid_nl)
      this._getApiDataNightLifeVipPerformanceId(url_api_get_pid, request_pid_nlvip)
    }

  }

  _getApiDataDaytimePerformanceId(url, request) {
    const _this = this
    fetch(url, {
      mode: "cors",
      method: 'POST',
      headers: _this.headers,
      body: JSON.stringify(request)
    })
    .then((response) => {
      return response.json()
    }).then((data) => {
      if (data[0]) {
        if (data[0]['PerformanceId']) {
          _this.dataApiDaytimePerformanceId = data[0]['PerformanceId']
          if (_this.state.dataApiPricesDaytimeTimed) {
            _this._getApiDataTimedDaytimeZoneTimes()
          } else {
            _this._getApiDataDaytimePrices()
          }
        } else {
          console.log('API fetch DaytimePerformanceId failed: results empty')
        }
      } else {
        console.log('API fetch DaytimePerformanceId failed: results empty')
      }

    }).catch((ex) => {
      console.log('API fetch DaytimePerformanceId failed: fetching again in 60 seconds', ex)
      setTimeout(() => {
        _this._getApiDataDaytimePerformanceId(url, request)
      }, 60000)
    })
  }

  _getApiDataNightLifePerformanceId(url, request) {
    const _this = this
    fetch(url, {
      mode: "cors",
      method: 'POST',
      headers: _this.headers,
      body: JSON.stringify(request)
    })
    .then((response) => {
      return response.json()
    }).then((data) => {
      if (data[0]) {
        if (data[0]['PerformanceId']) {
          _this.dataApiNightLifePerformanceId = data[0]['PerformanceId']
          if (_this.state.dataApiPricesNightLifeTimed) {
            _this._getApiDataTimedNightLifeZoneTimes()
          } else {
            _this._getApiDataNightLifePrices()
          }
        } else {
          console.log('API fetch NightLifePerformanceId failed: results empty')
        }
      } else {
        console.log('API fetch NightLifePerformanceId failed: results empty')
      }
    }).catch((ex) => {
      console.log('API fetch NightLifePerformanceId failed: fetching again in 60 seconds', ex)
      setTimeout(() => {
        _this._getApiDataNightLifePerformanceId(url, request)
      }, 60000)
    })
  }

  _getApiDataNightLifeVipPerformanceId(url, request) {
    const _this = this
    fetch(url, {
      mode: "cors",
      method: 'POST',
      headers: _this.headers,
      body: JSON.stringify(request)
    })
    .then((response) => {
      return response.json()
    }).then((data) => {
      if (data[0]) {
        if (data[0]['PerformanceId']) {
          _this.dataApiNightLifeVipPerformanceId = data[0]['PerformanceId']
          _this._getApiDataNightLifeVipPrices()
        } else {
          console.log('API fetch NightLifeVipPerformanceId failed: results empty')
        }
      } else {
        console.log('API fetch NightLifeVipPerformanceId failed: results empty')
      }
    }).catch((ex) => {
      console.log('API fetch NightLifeVipPerformanceId failed: fetching again in 60 seconds', ex)
      setTimeout(() => {
        _this._getApiDataNightLifeVipPerformanceId(url, request)
      }, 60000)
    })
  }

  _getApiDataDaytimePrices() {
    const _this = this
    let pid = this.dataApiDaytimePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Prices?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/PricesStaging?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._setDaytimePrices(data)
        } else {
          console.log('API fetch DaytimePrices failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch DaytimePrices failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataDaytimePrices()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch DaytimePrices abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataDaytimePrices()
      }, 60000)
    }
  }

  _setDaytimePrices(data) {

    const _this = this
    Array.from(data).forEach((item) => {

      if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['ADULT']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceAdult: item.Price.toString()
          })
        }
      }
      else if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['SENIOR']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceSenior: item.Price.toString()
          })
        }
      }
      else if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['STUDENT']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceStudent: item.Price.toString()
          })
        }
      }
      else if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['YOUTH']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceYouth: item.Price.toString()
          })
        }
      }
    })
  }

  _getApiDataTimedDaytimeZoneTimes() {
    const _this = this
    //let pid = this.dataApiTimedDaytimePerformanceId
    let pid = this.dataApiDaytimePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Zones?performanceIds=' + pid
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/ZonesStaging?performanceIds=' + pid
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._getApiDataTimedDaytimeZoneTimesPrices(data)
        } else {
          console.log('API fetch TimedDaytimeZoneTimes failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch TimedDaytimeZoneTimes failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataTimedDaytimeZoneTimes()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch TimedDaytimeZoneTimes abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataTimedDaytimeZoneTimes()
      }, 60000)
    }
  }

  _getApiDataTimedDaytimeZoneTimesPrices(zones) {
    const _this = this
    let pid = this.dataApiDaytimePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Prices?performanceIds=' + pid
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/PricesStaging?performanceIds=' + pid
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._setApiDataTimedDaytimePrices(zones, data)
          // retrieved timed prices need to be aggressively refreshed in UI
          _this._startTimedDaytimePriceRefreshInterval(zones, data)
        } else {
          console.log('API fetch TimedDaytimeZoneTimesPrices failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch TimedDaytimeZoneTimesPrices failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataTimedDaytimeZoneTimesPrices()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch TimedDaytimeZoneTimesPrices abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataTimedDaytimeZoneTimesPrices()
      }, 60000)
    }
  }

  _setApiDataTimedDaytimePrices(zones, prices) {

    const _this = this
    let arrZoneTimes = []

    Array.from(zones).forEach((item) => {
      let time = new Date(_this.now)
      let arr = item.Zone.ZoneTime.split(":")
      if (arr.length === 3) {
        let hours = arr[0]
        let minutes = arr[1]
        let seconds = arr[2]
        time.setHours(hours)
        time.setMinutes(minutes)
        time.setSeconds(seconds)
        let objZoneTime = {
           "id": item.Zone.Id,
           "time": time
        }
        arrZoneTimes.push(objZoneTime)
      }
    })
    // earliest first
    arrZoneTimes.sort((a, b) => a.time - b.time)
    let activeZoneTimeId = null
    arrZoneTimes.forEach((item, i) => {
      if (i < (arrZoneTimes.length - 1)) {
        if ((arrZoneTimes[i + 1]['time'] > _this.now) && (!activeZoneTimeId)) {
          activeZoneTimeId = item.id
        }
      } else {
        if (!activeZoneTimeId) {
          activeZoneTimeId = item.id
        }
      }
    })

    Array.from(prices).forEach((item) => {

      if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['ADULT']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceAdult: item.Price.toString()
          })
        }
      }
      else if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['SENIOR']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceSenior: item.Price.toString()
          })
        }
      }
      else if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['STUDENT']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceStudent: item.Price.toString()
          })
        }
      }
      else if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['YOUTH']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiDaytimePriceYouth: item.Price.toString()
          })
        }
      }
    })
  }

  _getApiDataNightLifePrices() {
    const _this = this
    let pid = this.dataApiNightLifePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Prices?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/PricesStaging?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._setNightLifePrices(data)
        } else {
          console.log('API fetch NightLifePrices failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch NightLifePrices failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataNightLifePrices()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch NightLifePrices abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataNightLifePrices()
      }, 60000)
    }
  }

  _setNightLifePrices(data) {
    const _this = this
    Array.from(data).forEach((item) => {
      if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['ADULT']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifePricePublic: item.Price.toString()
          })
        }
      }
      else if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['MEMBER']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifePriceMember: item.Price.toString()
          })
        }
      }
    })
  }

  _getApiDataNightLifeVipPrices() {
    const _this = this
    let pid = this.dataApiNightLifeVipPerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Prices?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/PricesStaging?performanceIds=' + pid + '&modeOfSaleId=' + this.API_MODE_OF_SALE
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._setNightLifeVipPrices(data)
        } else {
          console.log('API fetch NightLifeVipPrices failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch NightLifeVipPrices failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataNightLifeVipPrices()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch NightLifeVipPrices abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataNightLifeVipPrices()
      }, 60000)
    }
  }

  _setNightLifeVipPrices(data) {
    const _this = this
    Array.from(data).forEach((item) => {
      if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['ADULT']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifeVipPricePublic: item.Price.toString()
          })
        }
      }
      else if (item.PriceTypeId === _this.API_PRICE_TYPE_IDS['MEMBER']) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifeVipPriceMember: item.Price.toString()
          })
        }
      }
    })
  }

  _getApiDataTimedNightLifeZoneTimes() {
    const _this = this
    let pid = this.dataApiNightLifePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Zones?performanceIds=' + pid
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/ZonesStaging?performanceIds=' + pid
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._getApiDataTimedNightLifeZoneTimesPrices(data)
        } else {
          console.log('API fetch TimedNightLifeZoneTimes failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch TimedNightLifeZoneTimes failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataTimedNightLifeZoneTimes()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch TimedNightLifeZoneTimes abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataTimedNightLifeZoneTimes()
      }, 60000)
    }
  }

  _getApiDataTimedNightLifeZoneTimesPrices(zones) {
    const _this = this
    let pid = this.dataApiNightLifePerformanceId
    if (pid) {
      let url = this.API_ENDPOINT_BASE + '/Prices?performanceIds=' + pid
      if (this.isStaging) {
        url = this.API_ENDPOINT_BASE + '/PricesStaging?performanceIds=' + pid
      }
      fetch(url, {
        mode: "cors",
        method: 'GET',
        headers: _this.headers
      })
      .then((response) => {
        return response.json()
      }).then((data) => {
        if (data) {
          _this._setApiDataTimedNightLifePrices(zones, data)
          // retrieved timed prices need to be aggressively refreshed in UI
          _this._startTimedNightLifePriceRefreshInterval(zones, data)
        } else {
          console.log('API fetch TimedNightLifeZoneTimesPrices failed: results empty')
        }
      }).catch((ex) => {
        console.log('API fetch TimedNightLifeZoneTimesPrices failed: fetching again in 60 seconds', ex)
        setTimeout(() => {
          _this._getApiDataTimedNightLifeZoneTimesPrices()
       }, 60000)
      })
    } else {
      setTimeout(() => {
        console.log('API fetch TimedNightLifeZoneTimesPrices abandoned - no pid: trying again in 60 seconds')
        _this._getApiDataTimedNightLifeZoneTimesPrices()
      }, 60000)
    }
  }

  _setApiDataTimedNightLifePrices(zones, prices) {

    const _this = this
    let arrZoneTimes = []

    Array.from(zones).forEach((item) => {
      let time = new Date(_this.now)
      let arr = item.Zone.ZoneTime.split(":")
      let hours = arr[0]
      let minutes = arr[1]
      let seconds = arr[2]
      time.setHours(hours)
      time.setMinutes(minutes)
      time.setSeconds(seconds)
      let objZoneTime = {
         "id": item.Zone.Id,
         "time": time
      }
      arrZoneTimes.push(objZoneTime)
    })
    // earliest first
    arrZoneTimes.sort((a, b) => a.time - b.time)
    let activeZoneTimeId = null
    arrZoneTimes.forEach((item, i) => {
      if (i < (arrZoneTimes.length - 1)) {
        if ((arrZoneTimes[i + 1]['time'] > _this.now) && (!activeZoneTimeId)) {
          activeZoneTimeId = item.id
        }
      } else {
        if (!activeZoneTimeId) {
          activeZoneTimeId = item.id
        }
      }
    })

    Array.from(prices).forEach((item) => {

      if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['ADULT']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifePricePublic: item.Price.toString()
          })
        }
      }
      else if ((item.PriceTypeId === _this.API_PRICE_TYPE_IDS['MEMBER']) && (item.ZoneId === activeZoneTimeId)) {
        if ((parseInt(item.Price) % 1) === 0) {
          _this.setState({
            dataApiNightLifePriceMember: item.Price.toString()
          })
        }
      }
    })
  }

  _getCmsData(target) {
    let caps = target.toUpperCase()
    let url = 'URL_CMS_' + caps
    if (this.isStaging) {
      url = url + '_STAGING'
    }
    const _this = this
    fetchJsonp(this[url])
    .then((response) => {
      return response.json()
    }).then((data) => {
      this.setState ({
        ['dataCms' + target]: data,
        ['isLoaded' + target]: true
      })

      if (target === "Scenarios") {
        this._isNightLifeToday()
      }

    }).catch((ex) => {
      console.log('Initital JSON ' + target + ' fetch failed: fetching again in 30 seconds', ex)
      setTimeout(() => {
        _this._getCmsData(target)
     }, 30000)
    })
  }

  _queryParams() {
    let urlParams = new URLSearchParams(window.location.search)
    // REQUIRED QUERYSTRING VAL FORMAT: YYYY-MM-DDTHH:MM:SS
    if (urlParams.get('timewarp')) {
      this.now = new Date(urlParams.get('timewarp'))
      this.queryTimewarp = urlParams.get('timewarp')
    }
    // REQUIRED QUERYSTRING VAL: nid for frame
    if (urlParams.get('frame')) {
      this.queryFramePreview = urlParams.get('frame')
    }
    // REQUIRED QUERYSTRING KEY: staging
    if (urlParams.get('staging')) {
      this.isStaging = true
    }
  }

  _prepareFramePreviewData() {

    let nid = this.queryFramePreview

    let previewFrame = this.state.dataCmsFrames.find((frame) => {
      return frame.nid === nid
    })

    if (previewFrame) {
      let items = []
      items = this.state.dataCmsItems.filter((item) => {
        return item.nid === nid
      })

      this.previewDataFrame = previewFrame
      this.previewDataItems = items
      this.previewDataPromos = this.state.dataCmsPromos

    }

  }

  _selectDisplay(e, d) {
    e.preventDefault()
    this.setState({
      display: d
    })
  }

  _loadComplete() {
    if (this.queryFramePreview) {
      this.setState({
        display: 'frame'
      })
      this._prepareFramePreviewData()
    } else {
      if (this.state.dataApiUse) {
        if (this.state.dataApiLoaded) {
          this.setState({
            display: 'stage'
          })
        }
      } else {
        this.setState({
          display: 'stage'
        })
      }
    }
    clearInterval(this.intervalId)
    this.intervalId = null
    if ((!this.queryTimewarp) && (!this.queryFramePreview)) {
      this.intervalId = setInterval(() => this._checkForScenarioChange(), 60000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {

      if (
        (!this.state.dataCmsLoaded) ||
        (!this.state.dataApiLoaded)
      ) {
        if (!this.state.dataCmsLoaded) {
          if (
            this.state.isLoadedScenarios &&
            this.state.isLoadedFrames &&
            this.state.isLoadedItems &&
            this.state.isLoadedPromos &&
            this.state.dataHoursLoaded
          ) {
            if (
              !this.state.activeScenario &&
              !this.state.activeFrames &&
              !this.state.activeItems &&
              !this.state.activePromos &&
              !this.state.activeStartTime
            ) {
              this._parseData()
            } else {
              if (
                this.state.activeScenario &&
                this.state.activeFrames &&
                this.state.activeItems &&
                this.state.activePromos &&
                this.state.activeStartTime
              ) {
                this.setState({
                  dataCmsLoaded: true
                })
                if (this.state.dataApiUse) {
                  this._getApiData()
                }
              }
            }
          }
        }
        else if (!this.state.dataApiLoaded) {

          let todayCloseTime = new Date(this.state.todayCloseTimeUnix * 1000)

          // if now is after today's closing hours and NOT NightLife day,
          // actual api data not required (in case of app restart)
          if ((this.now >= todayCloseTime) &&
            (!this.state.isNightLifeToday)
          ) {
            this.setState({
              dataApiLoaded: true
            })
            this._startApiDataRefreshInterval()
          // if NL scheduled today and now is after NL end, data not req'd
          // in case app restarted after NL and all perfs cleared
          } else if (this.state.isNightLifeToday &&
            this.state.todayNightLifeEnd &&
            (this.now >= this.state.todayNightLifeEnd)
          ) {
            this.setState({
              dataApiLoaded: true
            })
            this._startApiDataRefreshInterval()
          // if NL scheduled today and now is after NL start
          // in case app restarted after daytime perfs cleared
          } else if (this.state.isNightLifeToday &&
            this.state.todayNightLifeStart &&
            (this.now >= this.state.todayNightLifeStart)
          ) {
            if (
              this.state.dataApiNightLifePricePublic &&
              this.state.dataApiNightLifePriceMember &&
              this.state.dataApiNightLifeVipPricePublic
            ) {
              this.setState({
                dataApiLoaded: true
              })
              this._startApiDataRefreshInterval()
            }
          // if now is after today's closing hours and IS NightLife day,
          // NL api data required (in case of app restart)
          } else if ((this.now >= todayCloseTime) &&
            (this.state.isNightLifeToday)
          ) {
            if (
              this.state.dataApiNightLifePricePublic &&
              this.state.dataApiNightLifePriceMember &&
              this.state.dataApiNightLifeVipPricePublic
            ) {
              this.setState({
                dataApiLoaded: true
              })
              this._startApiDataRefreshInterval()
            }
          // if NL scheduled today
          } else if (this.state.isNightLifeToday) {
            if (
              this.state.dataApiDaytimePriceAdult &&
              this.state.dataApiDaytimePriceSenior &&
              this.state.dataApiDaytimePriceStudent &&
              this.state.dataApiDaytimePriceYouth &&
              this.state.dataApiNightLifePricePublic &&
              this.state.dataApiNightLifePriceMember &&
              this.state.dataApiNightLifeVipPricePublic
            ) {
              this.setState({
                dataApiLoaded: true
              })
              this._startApiDataRefreshInterval()
            }
          // if NL not scheduled today
          } else {
            if (
              this.state.dataApiDaytimePriceAdult &&
              this.state.dataApiDaytimePriceSenior &&
              this.state.dataApiDaytimePriceStudent &&
              this.state.dataApiDaytimePriceYouth
            ) {
              this.setState({
                dataApiLoaded: true
              })
              this._startApiDataRefreshInterval()
            }
          }
        }
      }
    }
  }

  _getFrames(fids, promos) {
    // if active frame includes promos (ids), insure that at least 2 are
    // for promos that are NOT exired - otherwise do not include frame
    const _this = this
    let frames = []
    fids.forEach((fid) => {
      let frame = _this.state.dataCmsFrames.find((item) => {
        return item.nid === fid
      })
      let activePromoCount = 0
      frame.promos.forEach((pid) => {
        promos.forEach((promo) => {
          if (promo.nid === pid) {
            activePromoCount++
          }
        })
      })
      if ((!frame.promos.length) || (activePromoCount >= 2)) {
        frames.push(frame)
      }
    })
    return frames
  }

  _getItems(arr) {
    let items = []
    items = this.state.dataCmsItems.filter((item) => {
      return arr.indexOf(item.nid) > -1
    })
    return items
  }

  _getPromos() {

    // for now filter promos baased on:
    // promo unix_time_start_to_end matches now

    const _this = this
    let promos = []

    this.state.dataCmsPromos.forEach((promo, i) => {

      let activeMatch = false
      let arrUnixDateTime = promo.unix_time_start_to_end

      activeMatch = arrUnixDateTime.some((d) => {
        let start = null
        let end = null
        let arrStartEnd = d.split(' to ')
        if (arrStartEnd.length === 2) {
          start = new Date(arrStartEnd[0] * 1000)
          end = new Date(arrStartEnd[1] * 1000)
          if ((_this._compareDates(start, _this.now) === 'before') && (_this._compareDates(end, _this.now) === 'after')) {
            return true
          }
        } else {
          start = new Date(d * 1000)
          if (_this._compareDates(start, _this.now) === 'before') {
              return true
          }
        }
        return false
      })

      if (activeMatch) {
        promos.push(promo)
      }

    })

    return promos

  }

  _sameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    )
  }

  _isNightLifeToday() {
    const _this = this
    let todayNightLifeStart = null
    let todayNightLifeEnd = null

    let nl = this.state.dataCmsScenarios.filter((item) => {
      return item.administrative_title === "TOD NL"
    })
    if (nl[0].unix_time_start_to_end) {
      let activeMatch = false
      let arrUnixDateTime = nl[0].unix_time_start_to_end
      activeMatch = arrUnixDateTime.some((d) => {
        let start = null
        let end = null
        let arrStartEnd = d.split(' to ')
        if (arrStartEnd.length === 2) {
          start = new Date(arrStartEnd[0] * 1000)
          end = new Date(arrStartEnd[1] * 1000)
          if (_this._sameDay(start, _this.now)) {
            todayNightLifeStart = start
            todayNightLifeEnd = end
            return true
          }
        }
        return false
      })
      if (activeMatch) {
        _this.setState({
          isNightLifeToday: true,
          todayNightLifeStart: todayNightLifeStart,
          todayNightLifeEnd: todayNightLifeEnd
        })
      }
    }

  }

  _parseData() {

    const _this = this

    let defaultScenario = null
    let defaultFrames = null
    let defaultItems = null
    let defaultPromos = null
    let defaultStartTime = null

    let activeScenario = null
    let activeFrames = null
    let activeItems = null
    let activePromos = null
    let activeStartTime = null

    this.state.dataCmsScenarios.forEach((item, i) => {

      // "CLOSED" scenario
      if (item.default_scenario === '1') {
        defaultScenario = item
        defaultPromos = _this._getPromos()
        defaultFrames = _this._getFrames(item.frames, defaultPromos)
        defaultItems = _this._getItems(item.frames)
        defaultStartTime = new Date()
        defaultStartTime.setHours(7,0,0,0)
      }

      // "TOD DA" scenario handler
      // "TOD DA" scenario start/end comes from museum hours in event cal via utility server service
      else if (item.administrative_title === 'TOD DA') {
        // set activeScenario if museum currently open
        if (_this.state.todayOpenTimeUnix && _this.state.todayCloseTimeUnix) {
          let start = new Date(_this.state.todayOpenTimeUnix * 1000)
          let end = new Date(_this.state.todayCloseTimeUnix * 1000)
          if ((_this._compareTimes(start, _this.now) === 'before') && (_this._compareTimes(end, _this.now) === 'after')) {
            activeScenario = item
            activePromos = _this._getPromos()
            activeFrames = _this._getFrames(item.frames, activePromos)
            activeItems = _this._getItems(item.frames)
            activeStartTime = start
          }

          if (_this._isNonStandardDaytimeHours()) {
            // find nid for active "TOD DA hours" frame
            // AND create new "Today's Hours" item to append to activeItems w/ nid
            // AND change "TOD DA hours" frame subheader sales end time

            // RE-ADD 15 minutes to start timestamp for markup
            // RE-ADD 60 minutes to end timestamp for markup
            let open = new Date((_this.state.todayOpenTimeUnix + (15 * 60)) * 1000)
            let close = new Date((_this.state.todayCloseTimeUnix + (60 * 60)) * 1000)

            let openHours = open.getHours()
            let openMinutes = open.getMinutes()
            let closeHours = close.getHours()
            let closeMinutes = close.getMinutes()
            let salesStopHours = closeHours - 1

            let openMeridian = openHours >= 12 ? 'PM' : 'AM'
            let closeMeridian = closeHours >= 12 ? 'PM' : 'AM'
            let salesStopMeridian = salesStopHours >= 12 ? 'PM' : 'AM'

            openHours = openHours % 12
        		openHours = openHours ? openHours : 12
        		openMinutes = openMinutes < 10 ? '0' + openMinutes : openMinutes

            closeHours = closeHours % 12
        		closeHours = closeHours ? closeHours : 12
        		closeMinutes = closeMinutes < 10 ? '0' + closeMinutes : closeMinutes

            salesStopHours = salesStopHours % 12
        		salesStopHours = salesStopHours ? salesStopHours : 12

            if (activeFrames) {
              let objMuseumHoursFrame = activeFrames.find((frame) => {
                return frame.administrative_title === 'TOD DA hours'
              })
              if (objMuseumHoursFrame) {
                if (objMuseumHoursFrame.nid) {
                  let objListItem = {
                    "label": {
                      "value": "Today&rsquo;s Hours",
                      "format": "",
                      "safe_value": ""
                    },
                    "label_extra": "",
                    "static_value": {
                      "value": openHours + ":" + openMinutes + " <span class='caps'>" + openMeridian + "</span>&#8202;&ndash;&#8202;" + closeHours + ":" + closeMinutes + " <span class='caps'>" + closeMeridian + "</span>",
                      "format": "",
                      "safe_value": ""
                    },
                    "dynamic_value_id": "",
                    "description": "",
                    "nid": objMuseumHoursFrame.nid
                  }
                  // for uneven column list formatting when injecting list item
                  let objListItemEmpty = {
                    "label": {
                      "value": " ",
                      "format": "",
                      "safe_value": ""
                    },
                    "label_extra": "",
                    "static_value": {
                      "value": " ",
                      "format": "",
                      "safe_value": ""
                    },
                    "dynamic_value_id": "",
                    "description": "",
                    "nid": objMuseumHoursFrame.nid
                  }
                  if (activeItems) {
                    activeItems.unshift(objListItem)
                    activeItems.push(objListItemEmpty)
                  }
                }
              }

              activeFrames.forEach((frame) => {
                if (frame.administrative_title === 'TOD DA hours') {
                  // frame.subheader = {
                  //   "value": "Ticket sales end at " + salesStopHours + ":" + closeMinutes + " <span class='caps'>" + salesStopMeridian + "</span>.",
                  //   "format": "",
                  //   "safe_value": ""
                  // }
                  frame.description = {
                    "value": "Last entry sold at " + salesStopHours + ":" + closeMinutes + " <span class='caps'>" + salesStopMeridian + "</span>",
                    "format": "",
                    "safe_value": ""
                  }
                }
              })
            }

          }

        }

      // All other published scenarios
      } else {
        let startMatch = null
        let activeMatch = false
        let arrUnixDateTime = item.unix_time_start_to_end
        activeMatch = arrUnixDateTime.some((d) => {
          let start = null
          let end = null
          let arrStartEnd = d.split(' to ')
          if (arrStartEnd.length === 2) {
            start = new Date(arrStartEnd[0] * 1000)
            end = new Date(arrStartEnd[1] * 1000)
            if ((_this._compareDates(start, _this.now) === 'before') && (_this._compareDates(end, _this.now) === 'after')) {
              if ((_this._compareTimes(start, _this.now) === 'before') && (_this._compareTimes(end, _this.now) === 'after')) {
                startMatch = start
                return true
              }
            }
          } else {
            start = new Date(d * 1000)
            if (_this._compareDates(start, _this.now) === 'before') {
              if (_this._compareTimes(start, _this.now) === 'before') {
                startMatch = start
                return true
              }
            }
          }
          return false
        })

        if (activeMatch) {
          activeScenario = item
          activePromos = _this._getPromos()
          activeFrames = _this._getFrames(item.frames, activePromos)
          activeItems = _this._getItems(item.frames)
          activeStartTime = startMatch
        }
      }
      // End of all other published scenarios

    })

    if (!activeScenario) {
      activeScenario = defaultScenario
      activeFrames = defaultFrames
      activeItems = defaultItems
      activePromos = defaultPromos
      activeStartTime = defaultStartTime
    }

    this.setState({
      activeScenario: activeScenario,
      activeFrames: activeFrames,
      activeItems: activeItems,
      activePromos: activePromos,
      activeStartTime: activeStartTime
    })

  }

  render() {
    return (
      <div className="app">
        <div
          className={this.state.display === 'loader' ? 'container-loader show' : 'container-loader hide'}
          >
          <Loader
            dataHoursLoaded={this.state.dataHoursLoaded}
            dataCmsLoaded={this.state.dataCmsLoaded}
            dataApiLoaded={this.state.dataApiLoaded}
            dataApiUse={this.state.dataApiUse}
            handlerLoadComplete={this.handlerLoadComplete}
          />
        </div>
        <div
          className={this.state.display === 'nav' ? 'container-nav show' : 'container-nav hide'}
          >
          <Nav
            selectDisplay={this.handlerSelectDisplay}
          />
        </div>
        <div
          className={this.state.display === 'logo' ? 'container-logo show' : 'container-logo hide'}
          >
          <Logo
            selectDisplay={this.handlerSelectDisplay}
          />
        </div>

        <div
          className={this.state.display === 'frame' ? 'container-frame-preview show' : 'container-frame-preview hide'}
          onClick={(e) => this._selectDisplay(e, 'nav')}
          >
          <Frame
            dataFrame={this.previewDataFrame}
            dataItems={this.previewDataItems}
            dataPromos={this.previewDataPromos}
            dataApiUse={this.state.dataApiUse}
            dataApiDaytimePriceAdult={this.state.dataApiDaytimePriceAdult}
            dataApiDaytimePriceSenior={this.state.dataApiDaytimePriceSenior}
            dataApiDaytimePriceStudent={this.state.dataApiDaytimePriceStudent}
            dataApiDaytimePriceYouth={this.state.dataApiDaytimePriceYouth}
            dataApiNightLifePricePublic={this.state.dataApiNightLifePricePublic}
            dataApiNightLifePriceMember={this.state.dataApiNightLifePriceMember}
            dataApiNightLifeVipPricePublic={this.state.dataApiNightLifeVipPricePublic}
            dataApiNightLifeVipPriceMember={this.state.dataApiNightLifeVipPriceMember}
          />
        </div>

        <div
          className={this.state.display === 'stage' ? 'container-stage show' : 'container-stage hide'}
          >
          <Stage
            display={this.state.display}
            activeScenario={this.state.activeScenario}
            activeFrames={this.state.activeFrames}
            activeItems={this.state.activeItems}
            activePromos={this.state.activePromos}
            activeStartTime={this.state.activeStartTime}
            dataApiUse={this.state.dataApiUse}
            dataApiDaytimePriceAdult={this.state.dataApiDaytimePriceAdult}
            dataApiDaytimePriceSenior={this.state.dataApiDaytimePriceSenior}
            dataApiDaytimePriceStudent={this.state.dataApiDaytimePriceStudent}
            dataApiDaytimePriceYouth={this.state.dataApiDaytimePriceYouth}
            dataApiNightLifePricePublic={this.state.dataApiNightLifePricePublic}
            dataApiNightLifePriceMember={this.state.dataApiNightLifePriceMember}
            dataApiNightLifeVipPricePublic={this.state.dataApiNightLifeVipPricePublic}
            dataApiNightLifeVipPriceMember={this.state.dataApiNightLifeVipPriceMember}
            selectDisplay={this.handlerSelectDisplay}
          />
        </div>

      </div>
    )
  }
}

export default App
