import React, {Component} from 'react'
import '../style/Loader.css'

class Loader extends Component {

  componentDidUpdate(prevProps, prevState) {
    var _this = this
    if (
      (this.props.dataCmsLoaded !== prevProps.dataCmsLoaded) ||
      (this.props.dataApiLoaded !== prevProps.dataApiLoaded) ||
      (this.props.dataHoursLoaded !== prevProps.dataHoursLoaded)
    ) {
      if (
        this.props.dataCmsLoaded &&
        (this.props.dataApiLoaded || (!this.props.dataApiLoaded && !this.props.dataApiUse)) &&
        this.props.dataHoursLoaded
      ) {
        _this.props.handlerLoadComplete()
      }
    }
  }

  render() {

    return(
      <div id="loader">
        <div id="message-container">
          <p>This display is being updated.</p>
        </div>
      </div>
    )

  }

}

export default Loader
