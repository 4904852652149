import React, { Component } from 'react'
import '../style/Frame.css'
import Splash from './Splash'
import Details from './Details'
import Promos from './Promos'
import Exception from './Exception'

class Frame extends Component {

  render() {

    const _this = this

    let frameType = null
    let frameBackgroundImage = null

    let promos = []

    if (this.props.dataFrame) {

      // need promos ordered accoridng to promo cms entity ref order
      this.props.dataFrame.promos.forEach((pid) => {
        _this.props.dataPromos.forEach((promo) => {
          if (promo.nid === pid) {
            promos.push(promo)
          }
        })
      })

      if (!this.props.dataFrame.header && this.props.dataFrame.subheader) {
        frameType = "exception"
      } else if (!this.props.dataItems.length && !promos.length) {
        frameType = "splash"
      } else if (this.props.dataItems.length && !promos.length) {
        frameType = "details"
      } else if (!this.props.dataItems.length && promos.length) {
        frameType = "promos"
      }

      frameBackgroundImage = this.props.dataFrame.bg_image || this.props.dataFrame.bg_svg

    }

    let content = []

    let divStyle = {
      backgroundImage: 'url(' + frameBackgroundImage + ')'
    }

    switch (frameType) {
      case 'splash':
        content.push(
          <div
            key="0"
            className="container-splash"
            style={divStyle}
            >
            <Splash
              dataFrame={this.props.dataFrame}
            />
          </div>
        )
        break

      case 'details':
        content.push(
          <div
            key="0"
            className="container-details"
            style={divStyle}
            >
            <Details
              dataFrame={this.props.dataFrame}
              dataItems={this.props.dataItems}
              dataApiUse={this.props.dataApiUse}
              dataApiDaytimePriceAdult={this.props.dataApiDaytimePriceAdult}
              dataApiDaytimePriceSenior={this.props.dataApiDaytimePriceSenior}
              dataApiDaytimePriceStudent={this.props.dataApiDaytimePriceStudent}
              dataApiDaytimePriceYouth={this.props.dataApiDaytimePriceYouth}
              dataApiNightLifePricePublic={this.props.dataApiNightLifePricePublic}
              dataApiNightLifePriceMember={this.props.dataApiNightLifePriceMember}
              dataApiNightLifeVipPricePublic={this.props.dataApiNightLifeVipPricePublic}
              dataApiNightLifeVipPriceMember={this.props.dataApiNightLifeVipPriceMember}
            />
          </div>
        )
        break


      case 'promos':
        content.push(
          <div
            key="0"
            className="container-promos"
            style={divStyle}
            >
            <Promos
              dataFrame={this.props.dataFrame}
              dataPromos={promos}
            />
          </div>
        )
        break
      case 'exception':
        content.push(
          <div
            key="0"
            className="container-exception"
            style={divStyle}
            >
            <Exception
              dataFrame={this.props.dataFrame}
            />
          </div>
        )
        break
      default:
        break
    }

    return (
      <div
        className={this.props.activeFrame === this.props.id ? 'frame show-anim' : 'frame hide-anim'}
        >
          {content}
      </div>
    )
  }

}

export default Frame
