import React, { Component } from 'react'
import '../style/Details.css'

class Details extends Component {

  _getDynamicValue(id) {
    return this.props[id]
  }

  render() {

    const _this = this

    let strHeader = {__html: ''}
    let strSubheader = {__html: ''}
    let strDescription = {__html: ''}
    let listRows = []

    if (this.props.dataFrame) {

      if (this.props.dataFrame.header['value']) strHeader = {__html: this.props.dataFrame.header['value']}
      if (this.props.dataFrame.subheader['value']) strSubheader = {__html: this.props.dataFrame.subheader['value']}
      if (this.props.dataFrame.description['value']) strDescription = {__html: this.props.dataFrame.description['value']}

      this.props.dataItems.forEach((item, i) => {
        let strLabel = {__html: ''}
        let strItemDescription = {__html: ''}

        let strStaticValue = ''
        let strDynamicValueId = ''
        let strFinalValue = {__html: ''}

        let strLabelProcessed = ''
        if (item.label['value'] && item.label_extra['value']) {
          strLabelProcessed = item.label['value'] + item.label_extra['value']
        } else if (item.label['value']) {
          strLabelProcessed = item.label['value']
        }

        strLabel = {__html: strLabelProcessed}
        if (item.description['value']) strItemDescription = {__html: item.description['value']}

        if (item.static_value['value']) strStaticValue = item.static_value['value']
        if (item.dynamic_value_id) strDynamicValueId = item.dynamic_value_id

        if (!this.props.dataApiUse) {
          strFinalValue = {__html: strStaticValue}
        } else {
          if (strDynamicValueId.length > 0) {
            let dynamicPrice = _this._getDynamicValue(strDynamicValueId)
            if (dynamicPrice) {
              if (dynamicPrice.indexOf('.') === -1) dynamicPrice = dynamicPrice + ".00"
              let cents = dynamicPrice.split(".")[1]
              if (cents.length === 1) {
                dynamicPrice += "0"
              }
              strFinalValue = {__html: "$" + dynamicPrice}
            } else {
              strFinalValue = {__html: ""}
            }
          } else {
            strFinalValue = {__html: strStaticValue}
          }
        }

        listRows.push(
          <li
            key={i}
            >
              <div className="list-label"
                dangerouslySetInnerHTML={strLabel}
              />
              <div className="list-description"
                dangerouslySetInnerHTML={strItemDescription}
              />
              <div className="list-value"
                dangerouslySetInnerHTML={strFinalValue}
              />
          </li>
        )
      })

    }

    return (
      <div className="details">
        <h1
          dangerouslySetInnerHTML={strHeader}
        />
        <h2
          dangerouslySetInnerHTML={strSubheader}
        />
        <h3
          dangerouslySetInnerHTML={strDescription}
        />
        <div className="details-list">
          <ul>
            {listRows}
          </ul>
        </div>
      </div>
    )

  }

}

export default Details
