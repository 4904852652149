import React, { Component } from 'react'
import '../style/Splash.css'

class Splash extends Component {

  render() {

    let strHeader = {__html: ''}
    let strSubheader = {__html: ''}

    if (this.props.dataFrame) {
      if (this.props.dataFrame.header['value']) strHeader = {__html: this.props.dataFrame.header['value']}
      if (this.props.dataFrame.subheader['value']) strSubheader = {__html: this.props.dataFrame.subheader['value']}
    }

    return (
      <div className="splash">
        <h1
          dangerouslySetInnerHTML={strHeader}
        />
        <h2
          dangerouslySetInnerHTML={strSubheader}
        />
      </div>
    )

  }

}

export default Splash
